import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const Tags = (props) => {
  let tags = []
  for (let i of props.data.allPrismicBlogPost.edges) {
    if (i.node.tags) {
      for (let x of i.node.tags) if (!tags.includes(x)) tags = [...tags, x]
    }
  }
  return (
    <div className="categories-container">
      <div className="categories">
        <h4>Categories</h4>
        {tags.sort().map((tag, idx) => (
          <p key={idx}>
            <Link
              className={props.current === tag ? "active" : ""}
              to={"/blog/tags/" + tag.replace(/\s+/g, "-").toLowerCase()}
            >
              {tag}
            </Link>
          </p>
        ))}
        <p>
          <Link to="/blog">View All</Link>
        </p>
      </div>
    </div>
  )
}

const CategoryTags = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query Tags {
          allPrismicBlogPost {
            totalCount
            edges {
              node {
                id
                tags
              }
            }
          }
        }
      `}
      render={(data) => <Tags data={data} {...props} />}
    />
  )
}

export default CategoryTags
